<template>
  <div :class="classList">
    <div class="st-information-box__icon">
      <slot name="icon">
        <component :is="icon" :name="iconType.name" aria-hidden="true" />
      </slot>
    </div>
    <div class="st-information-box__description">
      <slot />
    </div>
  </div>
</template>

<script>
import StIcon from "../icon/Icon.vue";
import { markRaw } from "vue";

export default {
  name: "st-information-box",
  components: {
    StIcon,
  },
  props: {
    informationType: {
      type: String,
      default: "info",
      validator(informationType) {
        return ["info", "success", "failure", "danger"].includes(
          informationType
        );
      },
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icon: markRaw(StIcon),
    };
  },
  computed: {
    classList() {
      const infoBox = "st-information-box";
      return [
        infoBox,
        this.small && `${infoBox}--is-small`,
        this.informationType === "danger" && `${infoBox}--danger`,
      ];
    },
    iconType() {
      const icons = {
        success: { name: "checkmark" },
        failure: { name: "warning-filled" },
        info: { name: "info" },
        danger: { name: "info-danger" },
      };
      return icons[this.informationType];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";

.st-information-box {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  font-family: var(--font-primary);
  background: var(--container-color-secondary);
  color: var(--text-color-primary);
  padding: var(--md);
  @include medium {
    padding: var(--lg);
  }
  &__icon {
    display: flex;
    margin-right: var(--sm);
  }
  &__description {
    font-size: rem-calc(16);
    flex: 1 1 auto;
  }
  &--is-small {
    display: inline-flex;
  }
  &--danger {
    background: var(--danger-color-secondary);
    border: rem-calc(2) solid var(--danger-color-primary);
  }
}
</style>
