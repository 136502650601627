<template>
  <th
    :class="classList"
    :aria-label="header.name"
    @click="header.sortBy ? sort(header.sortBy) : () => {}"
    @keydown.enter="header.sortBy ? sort(header.sortBy) : () => {}"
  >
    <div class="st-table-header__container">
      <span class="st-table-header__container__title" aria-hidden="true">
        {{ header.name }}
      </span>
      <div
        v-if="header.sortBy"
        class="st-table-header__container__sort"
        role="button"
        tabindex="0"
        @click.stop="header.sortBy ? sort(header.sortBy) : () => {}"
        @keydown.enter.stop="header.sortBy ? sort(header.sortBy) : () => {}"
        aria-live="polite"
        :aria-label="`Sortera ${header.name} på ${
          sortOrder === 'asc' ? 'fallande' : 'stigande'
        }`"
      >
        <st-icon
          name="arrow-down"
          :alt="header.name"
          :icon-props="{ color: sortingColor('asc') }"
          aria-hidden="true"
        />
        <st-icon
          name="arrow-down"
          :alt="header.name"
          :icon-props="{ color: sortingColor('desc') }"
          aria-hidden="true"
        />
      </div>
    </div>
  </th>
</template>

<script>
import StIcon from "../icon/Icon.vue";
import { ref, computed } from "vue";
export default {
  components: { StIcon },
  props: {
    header: Object,
    isSorted: Boolean,
  },
  setup(props, { emit }) {
    const sortOrder = ref("desc");

    const classList = computed(() => {
      return [
        "st-table-header",
        props.header.sortBy && "st-table-header--is-sortable",
      ];
    });

    const sort = (sortBy) => {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
      emit("sort", sortBy, sortOrder.value);
    };

    const sortingColor = (direction) => {
      return props.isSorted && sortOrder.value === direction
        ? "#6D202B"
        : "#B6B2B0";
    };

    return {
      classList,
      sort,
      sortingColor,
      sortOrder,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-table-header {
  user-select: none;
  @include large {
    padding: rem-calc(22);
  }
  &--is-sortable {
    cursor: pointer;
  }
  &__container {
    display: flex;
    align-items: center;

    &__title {
      color: var(--text-color-primary);
      font-weight: 600;
      word-break: break-all;
      font-size: rem-calc(16);
    }

    & > :not(:last-child) {
      margin-right: rem-calc(10);
    }

    &__sort {
      display: flex;
      flex-direction: column;
      &:focus {
        outline: rem-calc(2) solid var(--border-color-quaternary);
      }

      & > :first-child {
        display: flex;
        margin-bottom: rem-calc(3);
        transform: rotate(180deg);
      }

      & > :last-child {
        display: flex;
      }
    }
  }
}
</style>
