import { ref, watch } from "vue";

export function useTheme(initial) {
  const currentTheme = ref(initial);
  const available = ["st", "ot", "bt"];

  function setTheme(value) {
    if (!available.includes(value)) {
      console.warn(`Theme "${value}" is not available.`);
      return;
    }

    currentTheme.value = value;
  }

  function useThemeComponent(components) {
    let fallbackComponent = components[0];
    for (const component of components) {
      if (!component.theme || !available.includes(component.theme)) {
        console.warn(
          `Invalid or missing value for variable "theme" in component: ${
            component.name || component.__name
          }.`
        );
      }
      if (component.theme === currentTheme.value.toLowerCase()) {
        return component;
      }
    }
    return fallbackComponent;
  }

  function setDocumentAttribute(value) {
    document.documentElement.setAttribute("data-theme", value);
  }

  watch(
    () => currentTheme.value,
    (value) => {
      setDocumentAttribute(value);
    },
    { immediate: true }
  );

  return {
    theme: currentTheme,
    setTheme,
    useThemeComponent,
  };
}
