<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_22_4)">
      <path
        d="M21.664 20.31H2.33399C1.11199 20.31 -0.00201416 19.196 -0.00201416 17.974V15.85C-0.00201416 15.392 0.367986 15.022 0.825986 15.022C1.67599 15.022 3.77599 13.846 3.77599 12.072C3.77599 10.654 2.64799 9.122 0.825986 9.122C0.367986 9.122 -0.00201416 8.752 -0.00201416 8.294V6.024C-0.00201416 4.804 1.11199 3.688 2.33399 3.688H21.664C22.886 3.688 24 4.802 24 6.024V8.294C24 8.752 23.63 9.122 23.172 9.122C22.414 9.122 20.982 9.74 20.982 12.072C20.982 14.472 22.89 15.022 23.172 15.022C23.63 15.022 24 15.392 24 15.85V17.97C24.002 19.194 22.888 20.31 21.664 20.31ZM1.65399 16.55V17.972C1.65399 18.278 2.02999 18.654 2.33599 18.654H21.662C21.968 18.654 22.344 18.278 22.344 17.972V16.534C21.014 16.096 19.322 14.696 19.322 12.076C19.322 9.264 20.918 7.942 22.344 7.578V6.026C22.344 5.72 21.968 5.344 21.662 5.344H2.33199C2.02599 5.344 1.64999 5.72 1.64999 6.026V7.54C4.00599 7.95 5.42999 10.084 5.42999 12.074C5.42999 14.448 3.27599 16.096 1.65399 16.552V16.55Z"
        :fill="color"
      />
      <path
        d="M15.348 16.97C14.196 16.97 13.262 16.036 13.262 14.884C13.262 13.732 14.196 12.798 15.348 12.798C16.5 12.798 17.434 13.732 17.434 14.884C17.432 16.034 16.5 16.97 15.348 16.97ZM15.348 14.454C15.106 14.454 14.91 14.65 14.91 14.892C14.91 15.134 15.106 15.33 15.348 15.33C15.59 15.33 15.786 15.134 15.786 14.892C15.786 14.89 15.786 14.888 15.786 14.886C15.786 14.644 15.59 14.448 15.348 14.448V14.454Z"
        :fill="color"
      />
      <path
        d="M8.22998 16.924C7.77398 16.924 7.40198 16.554 7.40198 16.098C7.40198 16.096 7.40198 16.096 7.40198 16.094V15.168C7.40198 14.71 7.77198 14.34 8.22998 14.34C8.68798 14.34 9.05798 14.71 9.05798 15.168V16.094V16.096C9.05798 16.552 8.68798 16.922 8.23198 16.922L8.22998 16.924Z"
        :fill="color"
      />
      <path
        d="M8.22998 13.298C7.77198 13.298 7.40198 12.928 7.40198 12.47V11.542C7.40198 11.084 7.77198 10.714 8.22998 10.714C8.68798 10.714 9.05798 11.084 9.05798 11.542V12.47C9.05798 12.928 8.68798 13.298 8.22998 13.298Z"
        :fill="color"
      />
      <path
        d="M8.22998 9.674C7.77198 9.674 7.40198 9.304 7.40198 8.846V7.922C7.40198 7.464 7.77198 7.094 8.22998 7.094C8.68798 7.094 9.05798 7.464 9.05798 7.922V8.85C9.05598 9.306 8.68598 9.674 8.22998 9.674Z"
        :fill="color"
      />
      <path
        d="M16.522 8.738H14.136C13.678 8.738 13.308 8.368 13.308 7.91C13.308 7.452 13.678 7.082 14.136 7.082H16.522C16.98 7.082 17.35 7.452 17.35 7.91C17.35 8.368 16.98 8.738 16.522 8.738Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_22_4">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "ot-mobile",
  props: {
    color: {
      type: String,
      default: "#E10E1C",
    },
  },
};
</script>
