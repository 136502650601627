<template>
  <Calendar
    :inputId="id"
    :showButtonBar="showButtonBar"
    :minDate="new Date(minDate)"
    :maxDate="new Date(maxDate)"
    :manualInput="manualInput"
    :disabled="disabled"
    :invalid="invalid"
    @clear-click="focusInputOnClear()"
    :dateFormat="dateFormat"
    :pt="{
      root: classList,
      input: 'st-date-picker__input',
      panel: {
        class: 'st-date-picker-dropdown-group',
        ariaLabel: 'Kalender för att välja eller ändra datum',
      },
      header: 'st-date-picker__dropdown-group__header',
      daylabel: 'st-date-picker-dropdown-group__date',
      buttonbar: 'st-date-picker-dropdown-group__button-bar',
      nextbutton: {
        class: 'st-date-picker-dropdown-group__header__next-button',
        ariaLabel: 'Nästa månad',
      },
      previousbutton: {
        class: 'st-date-picker-dropdown-group__header__previous-button',
        ariaLabel: 'Föregående månad',
      },
      monthtitle: 'st-date-picker-dropdown-group__header__month-button',
      yeartitle: 'st-date-picker-dropdown-group__header__year-button',
      monthpicker: 'st-date-picker-dropdown-group__monthpicker',
      month: {
        class: 'st-date-picker-dropdown-group__monthpicker__month',
      },
      yearpicker: {
        class: 'st-date-picker-dropdown-group__yearpicker',
        id: 'picker',
      },
      year: 'st-date-picker-dropdown-group__yearpicker__year',
    }"
  >
  </Calendar>
</template>

<script setup>
import Calendar from "primevue/calendar";
import { computed, defineProps } from "vue";

const props = defineProps({
  id: { type: String, default: "datepickerInput" },
  showButtonBar: { type: Boolean, default: true },
  minDate: { type: [Date, String] },
  maxDate: { type: [Date, String] },
  manualInput: { type: Boolean, default: true },
  invalid: { type: Boolean, default: false },
  disabled: { type: Boolean },
  dateFormat: { type: String, default: "yy-mm-dd" },
});

const classList = computed(() => {
  const datepicker = "st-date-picker";
  return [
    datepicker,
    props.invalid && `${datepicker}--invalid`,
    props.disabled && `${datepicker}--disabled`,
  ];
});

const focusInputOnClear = () => {
  document.getElementById(props.id).focus();
};
</script>

<style lang="scss">
@import "../../scss/functions.scss";

.st-date-picker {
  display: flex;
  align-items: center;
  height: rem-calc(48);
  border: var(--border-width--thin) solid var(--border-color-quaternary);
  border-radius: rem-calc(6);
  width: 100%;
  &--invalid {
    border-color: var(--danger-color-primary);
    border-width: var(--border-width);
  }
  &--disabled {
    background-color: var(--container-color-secondary);
  }
  :disabled {
    background-color: var(--container-color-secondary);
  }
  &:focus-within {
    box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
      0 0 0 rem-calc(4) var(--border-color-quaternary);
    border-radius: rem-calc(6);
    outline: none;
  }
  &__input {
    border-color: transparent;
    color: var(--text-color-primary);
    font-family: var(--font-primary);
    width: 100%;
    padding-left: var(--lg);
    font-size: rem-calc(16);
    line-height: 1.5;
    margin-bottom: 0;

    &:focus {
      outline: none;
    }
  }
}

.st-date-picker-dropdown-group {
  color: var(--text-color-primary);

  &__header {
    &__month-button,
    &__year-button {
      color: var(--text-color-primary);
      &:hover {
        background-color: var(--background-color-quaternary);
      }
      &:focus {
        outline: rem-calc(1) solid var(--border-color-quaternary);
      }
    }

    &__next-button,
    &__previous-button {
      color: var(--text-color-primary);
      &:hover {
        background-color: var(--background-color-quaternary);
      }
      &:focus {
        outline: rem-calc(1) solid var(--border-color-quaternary);
      }
    }
  }

  &__monthpicker,
  &__yearpicker {
    &__month,
    &__year {
      color: var(--text-color-primary);
      &:hover {
        background-color: var(--background-color-quaternary);
      }
      &:focus-visible {
        outline-color: var(--border-color-quaternary);
      }
    }
  }

  &__date {
    &:hover {
      background-color: var(--background-color-quaternary);
    }
    &:focus {
      background-color: var(--background-color-quaternary);
      outline-color: var(--background-color-primary);
      color: var(--text-color-primary);
    }
  }

  // This is for the picked date
  .p-highlight {
    background-color: var(--background-color-primary) !important;
    color: var(--container-color-primary) !important;
    &:focus {
      outline: rem-calc(1) solid var(--border-color-quaternary);
    }
    &:hover {
      background-color: var(--background-color-quaternary) !important;
      color: var(--text-color-primary) !important;
    }
  }

  // This is for todays date
  .p-datepicker-today {
    span {
      background-color: transparent;
      border: solid rem-calc(1) var(--background-color-primary);
    }
    .st-date-picker-dropdown-group__date {
      &:hover {
        background-color: var(--background-color-quaternary);
      }
      &:focus {
        outline: rem-calc(1) solid var(--border-color-quaternary);
      }
    }
  }

  // This is for the button bar with 'today' and 'clear date'
  &__button-bar {
    button {
      color: var(--text-color-quinary);
      &:hover {
        background-color: var(--background-color-quaternary);
      }

      &:focus-visible {
        outline-color: var(--border-color-quaternary);
      }
    }
  }
}

// Needed to remove outline when pressing a date
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  outline: none;
}
</style>
