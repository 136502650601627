<template>
  <div :class="classList">
    <div class="st-tooltip__title">
      <slot name="title" />
      <div
        class="st-tooltip__title__icon"
        @click="handleClick"
        @keydown.enter="handleClick"
        role="button"
        tabindex="0"
        :aria-expanded="isExpanded"
        :aria-label="
          isExpanded ? 'Stäng informationsruta' : 'Öppna informationsruta'
        "
      >
        <st-icon
          :name="isExpanded ? 'remove' : 'info-on'"
          :icon-props="{ color: '#6D202B' }"
        />
      </div>
    </div>
    <div v-if="isExpanded" class="st-tooltip__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import StIcon from "../icon/Icon.vue";

export default {
  name: "st-tooltip",
  components: {
    StIcon,
  },
  setup() {
    const isExpanded = ref(false);

    const classList = computed(() => {
      return ["st-tooltip", isExpanded.value && "st-tooltip--expanded"];
    });

    const handleClick = () => {
      isExpanded.value = !isExpanded.value;
    };

    return {
      isExpanded,
      classList,
      handleClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-tooltip {
  padding-top: rem-calc(10);
  border-radius: rem-calc(6);
  &--expanded {
    padding: rem-calc(10);
    background-color: var(--background-color-quaternary);
    & > :first-child {
      margin-bottom: rem-calc(10);
      & > :last-child {
        margin-left: auto;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;

    &__icon {
      display: flex;
      align-self: center;
      margin-left: rem-calc(10);
      cursor: pointer;

      &:focus {
        outline: rem-calc(2) solid var(--border-color-quaternary);
      }
    }
  }

  &__content {
    color: var(--text-color-primary);
    font-family: var(--font-primary);
  }
}
</style>
