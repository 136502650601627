<template>
  <div class="st-error" :role="role">
    <div class="st-error__error-box">
      <div class="st-error__error-box__icon">
        <st-icon name="error-filled-large" :alt="alt" />
      </div>
      <div class="st-error__error-box__text">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import StIcon from "../icon/Icon.vue";

export default {
  components: {
    StIcon,
  },
  props: {
    errorMessage: {
      type: String,
      default: "Något gick snett, var god försök igen senare.",
    },
    alt: {
      type: String,
      default: "",
    },
    setRoleAlert: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const role = props.setRoleAlert ? "alert" : null;
    return {
      role,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/main.scss";
.st-error {
  &__error-box {
    border-radius: rem-calc(4);
    background-color: var(--danger-color-secondary);
    border: rem-calc(1) solid var(--danger-color-primary);
    min-height: rem-calc(36);
    padding: rem-calc(8) rem-calc(15);

    font-family: var(--font-primary);
    font-size: rem-calc(14);
    font-weight: 600;
    color: var(--text-color-primary);
    overflow-wrap: anywhere;
    display: flex;

    &__icon {
      display: flex;
      width: rem-calc(20);
      margin-right: rem-calc(10);
      align-self: center;
    }

    &__text {
      align-self: center;
    }
  }
}
</style>
