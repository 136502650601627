<template>
  <div class="st-icon" :aria-hidden="true">
    <component
      :is="dynamicComponent"
      :aria-label="alt"
      :size="iconSize"
      v-bind="iconProps"
    />
  </div>
</template>

<script setup>
import { defineProps, computed, inject, ref } from "vue";
import * as StIcons from "./st-icons.js";
import * as BtIcons from "./bt-icons.js";
import * as OtIcons from "./ot-icons.js";
const components = { ...StIcons, ...BtIcons, ...OtIcons };

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  iconProps: {
    type: Object,
  },
  alt: {
    type: String,
  },
  size: {
    type: String,
    validator(value) {
      return ["small", "medium", "large"].includes(value);
    },
    default: "medium",
  },
});

const { theme } = inject("theme", { theme: ref("st") });

const dynamicComponent = computed(() => {
  //Themeable and the theme has the icon.
  const themedIcon = iconName(theme.value);
  const existingThemedIcon = componentExist(themedIcon);
  if (existingThemedIcon) return components[convertToPascal(themedIcon)];

  //Fallback
  const fallbackIcon = iconName("st");
  return components[convertToPascal(fallbackIcon)];
});

const iconSize = computed(() => {
  const sizes = {
    small: 20,
    medium: 24,
    large: 40,
  };
  return sizes[props.size];
});
const iconName = (theme) => {
  return `${theme}-${props.name}`;
};
const componentExist = (componentName) => {
  const pascalName = convertToPascal(componentName);
  return Object.prototype.hasOwnProperty.call(components, pascalName);
};
const convertToPascal = (string) => {
  return string
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};
</script>

<style lang="scss" scoped>
.st-icon {
  display: inline-flex;
}
</style>
